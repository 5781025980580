import { Button } from '@econsult/econsult-ui/dist/components/Button';
import StyledButton from '@econsult/econsult-ui/dist/components/Button/ButtonStyles';
import { ColumnDiv, Div } from 'components/Div';
import { TableContainer } from 'components/Table';
import { ParagraphXSmallHeavy, ParagraphXSmallRegular, TextFlow } from 'components/Typography';
import styled from 'styled-components';
import welcomeGraphic from 'assets/welcomeGraphic.png';

export const HomeWrapper = styled(TableContainer)`
  gap: 2rem;
`;

export const StyledWelcomeGraphic = styled(Div)`
  align-items: center;
  justify-content: center;
  background-color: rgba(180, 234, 253, 0.25);
  overflow: hidden;
  border-radius: 0.75rem;
  background-image: url(${welcomeGraphic});
  background-size: cover;
  background-position: 50% 50%;
  background-blend-mode: multiply;
`;

export const HomeLinksWrapper = styled(Div)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
`;

export const HomeWelcomeWrapper = styled(Div)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colorPrimaryPrimary10};
  padding: 1.5rem;

  ${TextFlow} {
    grid-column: 2 / 5;
    flex: 1;
  }
`;

export const CardWrapper = styled(ColumnDiv)`
  width: 100%;
  padding: 1rem 1.25rem;
  gap: 1rem;
`;

export const CardTitle = styled(ParagraphXSmallHeavy).attrs({ as: 'h3' })`
  color: ${({ theme }) => theme.colorPrimaryPrimary80};
  border-bottom: 1px solid ${({ theme }) => theme.colorPrimaryPrimary20};
  line-height: 1.5;
`;

export const CardDescription = styled(ParagraphXSmallRegular)`
  color: ${({ theme }) => theme.colorPrimaryPrimary60};
`;

export const HomeCardButton = styled(Button)`
  &${StyledButton} {
    justify-content: start;
    color: ${({ theme }) => theme.colorPrimaryPrimary90};
    padding: 0;

    &:not(:hover) {
      background: transparent;
    }

    &:active {
      outline: none;

      &, & svg {
        background: ${({ theme }) => theme.colorPrimaryPrimary20};
      }
    }
    
    & svg {
      width: 1.5rem;
      height: 1.5rem;
      padding: 0.375rem;
      box-sizing: border-box;
      background: ${({ theme }) => theme.colorPrimaryPrimary10};
      color: ${({ theme }) => theme.colorPrimaryPrimary100};
      border-radius: 0.375rem;
    }
  }
`;
