/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import { STRINGS } from 'config';
import GitInfo from 'react-git-info/macro';
import { initialiseDatadog } from 'utils/datadog';
import { setupIntercom, shutDownIntercomSession } from 'utils/intercom';
import { loadCookieFirst, removeCookiesWithStringPrefix } from 'cookieFirst';
import App from './components/App';
import reportWebVitals from './reportWebVitals';

window.addEventListener('pageshow', event => {
  if (event.persisted) {
    location.reload(); // eslint-disable-line
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

export const loadAndRemoveAllConsentRequiredScripts = event => {
  const consent = event.detail || {};
  if (consent.performance) {
    initialiseDatadog();
  }
  if (consent.functional) {
    setupIntercom();
  }
  if (!consent.functional) {
    shutDownIntercomSession();
    removeCookiesWithStringPrefix('intercom', '.awshealth.net');
  }
  if (!consent.performance) {
    removeCookiesWithStringPrefix('_dd', null, 'Strict');
  }
};

export const addCookieConsentEventListeners = () => {
  window.addEventListener('cf_consent', loadAndRemoveAllConsentRequiredScripts);
  window.addEventListener('cf_consent_loaded', loadAndRemoveAllConsentRequiredScripts);
};

if (STRINGS.COOKIEFIRST.ENABLED) {
  addCookieConsentEventListeners();
  loadCookieFirst();
} else {
  initialiseDatadog();
  setupIntercom();
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (STRINGS.ENVIRONMENT.IS_DEV_OR_TEST_ENV) {
  const gitInfo = GitInfo();
  console.log('VERSION INFO');
  console.log('--------------------------------------------------------');
  console.log(`BRANCH * ${gitInfo.branch}`);
  console.log(`GIT COMMIT DATE * ${new Date(gitInfo.commit.date)}`);
  console.log(`LAST MESSAGE * ${gitInfo.commit.message}`);
  console.log(`HASH * ${gitInfo.commit.hash}`);
  console.log(`DATE DEPLOYED * ${process.env.REACT_APP_DEPLOY_DATE}`);
  console.log('--------------------------------------------------------');
}
