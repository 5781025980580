import React, { useMemo } from 'react';
import styled from 'styled-components';
import { NavBar, NavBarMenuItem } from '@econsult/econsult-ui/dist/components/NavBar';
import { DiscoverTuneSvg, DoorOpenSvg, EditUserSvg, HomeSvg } from 'assets/svg';
import { useMatch, useNavigate } from 'react-router-dom';
import _upperFirst from 'lodash/upperFirst';
import { useAppState } from 'store/configureStore';

const HomeIcon = styled(HomeSvg)``;
const UserManagementIcon = styled(EditUserSvg)``;
const DemandManagementIcon = styled(DoorOpenSvg)``;

const NAVBAR_ITEM_IDS = {
  HOME: 'home',
  USER_MANAGEMENT: 'user-management',
  DEMAND_MANAGEMENT: 'demand-management',
  OTHER_SETTINGS: 'other-settings',
};
const notForEhubsText = 'not available to eHubs';

export default function CompactNavBar({ isExpanded, setIsExpanded }) {
  const { app: { selectedPracticeIsEhub } } = useAppState();
  const navigate = useNavigate();
  const match = useMatch('/:path');
  const selectedNavBarItemId = useMemo(() => (
    match?.params.path || NAVBAR_ITEM_IDS.HOME
  ), [match?.params.path]);
  const tooltipLabel = isExpanded
    ? _upperFirst(notForEhubsText)
    : notForEhubsText;

  const onItemClick = itemId => navigate(`/${itemId === NAVBAR_ITEM_IDS.HOME ? '' : itemId}`);

  return (
    <NavBar
      defaultActiveItemId={NAVBAR_ITEM_IDS.USER_MANAGEMENT}
      onItemClick={onItemClick}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      selectedItemId={selectedNavBarItemId}
      automationName="NavBarCompact"
      compact
      shouldOverflow
    >
      {!selectedPracticeIsEhub && (
        <NavBarMenuItem
          id={NAVBAR_ITEM_IDS.HOME}
          label="Home"
          automationName="HomeNavBarMenuItem"
          icon={<HomeIcon />}
          compact
        />
      )}

      <NavBarMenuItem
        id={NAVBAR_ITEM_IDS.USER_MANAGEMENT}
        label="User management"
        automationName="UserManagementNavBarMenuItem"
        icon={<UserManagementIcon />}
        compact
      />

      <NavBarMenuItem
        id={NAVBAR_ITEM_IDS.DEMAND_MANAGEMENT}
        label="Demand management"
        tooltipLabel={selectedPracticeIsEhub && tooltipLabel}
        automationName="DemandManagementNavBarMenuItem"
        icon={<DemandManagementIcon />}
        compact
        disabled={selectedPracticeIsEhub}
      />

      <NavBarMenuItem
        id={NAVBAR_ITEM_IDS.OTHER_SETTINGS}
        label="Other settings"
        tooltipLabel={selectedPracticeIsEhub && tooltipLabel}
        automationName="OtherSettingsNavBarMenuItem"
        icon={<DiscoverTuneSvg />}
        compact
        disabled={selectedPracticeIsEhub}
      />
    </NavBar>
  );
}
