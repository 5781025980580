import _capitalise from 'lodash/capitalize';
import _lowerCase from 'lodash/lowerCase';
import _startCase from 'lodash/startCase';

/* Converts camel, kebab and snake casing into a capitalised, space separated sentence */
export const capitalise = string => _capitalise(_lowerCase(string));

export const toTitleCase = string => _startCase(_lowerCase(string));

export const safeJsonParse = (json, onErrorCallback) => {
  try {
    return JSON.parse(json);
  } catch {
    return onErrorCallback();
  }
};

export const removeWhitespace = input => input.replace(/\s/g, '');

export const isValidEmail = email => {
  const rfc5322 = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const fallback = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
  return rfc5322.test(email) && fallback.test(email);
};

// This supports most languages and allows only for word and number characters. It supports spaces, hyphens, and apostrophes
export const isValidTextOrNumberString = input => /^[\w'\-,.0-9;:!?+%&*()][^_¡÷¿/\\=@#$ˆ{}|~<>[\]]*$/.test(input);
